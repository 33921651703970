export const messages: Record<string, Record<string, string | undefined>> = {
	de: {
		Connectors: 'Konnektoren',
		Overview: 'Übersicht',
		Settings: 'Einstellungen',
		'Connector Groups': 'Konnektorgruppen',
		'Application Targets': undefined,
		'ZTNA Connector': 'ZTNA Konnektoren'
	},
	fr: {
		Connectors: 'Connecteurs',
		Overview: 'Vue d’ensemble',
		Settings: 'Paramètres',
		'Connector Groups': 'Groupes de connecteurs',
		'Application Targets': undefined,
		'ZTNA Connector': 'Connecteur ZTNA'
	},
	ja: {
		Connectors: 'コネクタ',
		Overview: '概要',
		Settings: '設定',
		'Connector Groups': 'コネクタグループ',
		'Application Targets': undefined,
		'ZTNA Connector': 'ZTNA コネクタ'
	}
}
