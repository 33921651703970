const prefix = 'fas'
const iconName = 'sliders-h-square'
const width = 448
const height = 512
const ligatures: string[] = []
const unicode = 'f3f0'
const svgPathData =
	'M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-16 324c0 6.6-5.4 12-12 12h-52v24c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24v-24H76c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h180v-24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24v24h52c6.6 0 12 5.4 12 12v40zm0-160c0 6.6-5.4 12-12 12H192v24c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24v-24H76c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h52v-24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24v24h180c6.6 0 12 5.4 12 12v40z'

export const definition = {
	prefix: prefix,
	iconName: iconName,
	icon: [width, height, ligatures, unicode, svgPathData]
}
