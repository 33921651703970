export type ZtnaRouteGizmo = { ztna_route_urls: ReturnType<typeof ztnaRoutes> }

export function ztnaRoutes(baseUrl?: string) {
	return {
		baseUrl,
		overview: `${baseUrl}/overview`,
		applications: `${baseUrl}/app-targets`,
		fqdns: `${baseUrl}/fqdns`,
		subnets: `${baseUrl}/subnets`,
		wildcards: `${baseUrl}/wildcards`,
		connectors: `${baseUrl}/connectors`,
		connectorGroups: `${baseUrl}/connector-groups`
	}
}

/**
 * ZTNA routes on Prisma Access.
 *
 * @note This routes object violates component design,
 * as this package should not be aware of where it is being deployed.
 * (e.g. as a sparky micro app in Prisma Access, or as a standalone app).
 *
 * We violate the design here to make it easy to be consumed by any app on Fawkes.
 * An alternative is to create a simple wrapper package with this routes.
 *
 * But at this moment that is a bit overkilled.
 */
export const ztnaPrismaAccessRoutes = ztnaRoutes(`/settings/ztna-connector`)
