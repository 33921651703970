import type { Fetch } from '@pats/just-gateway'
import type { FeatureInfo } from '@sparky/framework'

/**
 * Is the specific Panorama tenant serviceable.
 *
 * Serviceable means it has at least ami: 10.2.4 and saas-agent: 4.0
 */
export async function isPanoramaTenantServiceable(
	{
		sessionStorage,
		fetch
	}: {
		sessionStorage: Pick<Storage, 'getItem' | 'setItem'>
		fetch: Fetch
	},
	baseUrl: string,
	tsgId: string,
	region: string,
	token: string
) {
	const key = `ztna-connector:panorama_tenants`
	const panoramaTenants = JSON.parse(sessionStorage.getItem(key) ?? '{}')
	if (panoramaTenants[tsgId] !== undefined) return panoramaTenants[tsgId]

	const res = await fetch(`${baseUrl}/v2.0/api/serviceable`, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-panw-region': region
		 }
	})
	if (res.status === 204) {
		panoramaTenants[tsgId] = true
		sessionStorage.setItem(key, JSON.stringify(panoramaTenants))
		return true
	}
	if (res.status === 424) {
		panoramaTenants[tsgId] = false
		sessionStorage.setItem(key, JSON.stringify(panoramaTenants))
		return false
	}

	return false
}

export type AvailParams = {
	featuresInfo?: FeatureInfo[]
	licenseInfo: { tsg_id: string }
}

export function isZtnaAvail(params: AvailParams) {
	return isFeatureEnabled(params.featuresInfo, 'ztna_connector')
}

export function isFeatureEnabled(featureInfo: FeatureInfo[] | undefined, featureKey: string) {
	return !!featureInfo?.some(f => f.name === featureKey && f.toggle === 1)
}
