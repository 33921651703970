import { ZTNA_APP_KEY } from '@pats/ztna-react-app'
import { ztnaPrismaAccessRoutes } from '@pats/ztna-react-app/manifest'
import type { AuthState, MainState } from '@sparky/framework'
import type { SparkyFramework } from '@sparky/framework/build/types/framework'
import { definition as icon } from './faSlidersHSquare'
import { isPanoramaTenantServiceable, isZtnaAvail } from './feature'
import { i18n } from './i18n'

export default function manifest({ sparkyFramework }: { sparkyFramework: SparkyFramework }) {
	return async ({ auth, main }: { auth: AuthState; main: MainState }) => {
		if (!auth.instances?.isFawkesRunning) return undefined

		const intl = i18n(navigator.language ?? 'en')

		const avail =
			main.tenant?.type === 'panorama' ? await createRemoteAvail(sparkyFramework) : isZtnaAvail

		const workflow = sparkyFramework.getFrameworkVar('use_new_left_nav')
		return {
			key: workflow ? 'workflows' : 'settings',
			title: workflow
				? intl.formatMessage({ defaultMessage: 'Workflows' })
				: intl.formatMessage({ defaultMessage: 'Settings' }),
			path: workflow ? '/workflows' : '/settings',
			expandable: true,
			noRBACheck: true,
			exact: false,
			icon,
			mergeNav: true,
			children: [
				{
					key: ZTNA_APP_KEY,
					path: ztnaPrismaAccessRoutes.baseUrl,
					title: intl.formatMessage({ defaultMessage: 'ZTNA Connector' }),
					contentClassName: 'panwds-tw3',
					expandable: true,
					// exact: false,
					// noRBACheck: true,
					avail,
					// @TODO: remove this once the new nav code from sparky is available
					callAvail: true,
					children: [
						{
							key: 'overview',
							title: intl.formatMessage({ defaultMessage: 'Overview' }),
							path: ztnaPrismaAccessRoutes.overview
						},
						{
							key: 'connector-groups',
							title: intl.formatMessage({ defaultMessage: 'Connector Groups' }),
							path: ztnaPrismaAccessRoutes.connectorGroups
						},
						{
							key: 'connectors',
							title: intl.formatMessage({ defaultMessage: 'Connectors' }),
							path: ztnaPrismaAccessRoutes.connectors
						},
						{
							key: 'app-targets',
							title: intl.formatMessage({ defaultMessage: 'Application Targets' }),
							path: ztnaPrismaAccessRoutes.applications
						}
					],
					component: async () => import('../main').then(m => m.activate())
				}
			]
		}
	}
}

async function createRemoteAvail(sparky: SparkyFramework) {
	const tsgId = sparky.getCurrentTsgId()
	const region = sparky.getState().auth?.instances?.get('logging_service')?.region
	if (!tsgId || !region) return () => false

	const env = sparky.getMicroAppVars('ztna-connector')
	const overrideUri = getOverrideApiUri(env, tsgId)

	const url = overrideUri
		? `https://${overrideUri}`
		: `${sparky.getFrameworkServiceUrl('api_gateway')!}/sse/connector`

	const token = await sparky.getCurrentTsgToken()

	const avail = await isPanoramaTenantServiceable(
		{ sessionStorage, fetch },
		url,
		tsgId,
		region,
		token
	)
	return function isAvail() {
		return avail
	}
}

/**
 * The `REDIRECT_APIx` will be `<hostname>/subpath` until the api version.
 *
 * e.g. `pa-api-us-dev02.tools.panclouddev.com/sse/connector/2`
 */
function getOverrideApiUri(env: Record<string, any> | undefined, tsgId: string | undefined) {
	if (hasTsgId(env?.REDIRECT_TSG1, tsgId)) {
		return env!.REDIRECT_API1 as string
	}
	if (hasTsgId(env?.REDIRECT_TSG2, tsgId)) {
		return env!.REDIRECT_API2 as string
	}
	if (hasTsgId(env?.REDIRECT_TSG3, tsgId)) {
		return env!.REDIRECT_API3 as string
	}
}

function hasTsgId(ids: string | undefined, tsgId?: string) {
	if (!tsgId) return false
	return (ids ?? '').split('-').includes(tsgId)
}
